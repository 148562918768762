// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cities-js": () => import("./../src/pages/cities.js" /* webpackChunkName: "component---src-pages-cities-js" */),
  "component---src-pages-foo-js": () => import("./../src/pages/foo.js" /* webpackChunkName: "component---src-pages-foo-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-submit-js": () => import("./../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-pages-tags-js": () => import("./../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-city-js": () => import("./../src/templates/City.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-contributor-js": () => import("./../src/templates/Contributor.js" /* webpackChunkName: "component---src-templates-contributor-js" */),
  "component---src-templates-location-js": () => import("./../src/templates/Location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

